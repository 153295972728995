import Page from "../lavora-con-noi";
import { graphql } from "gatsby";

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contactMail: contactsJson(jsonId: { eq: "info" }) {
      url
    }
    jobPositions: allJobPositionsJson(filter: { enabled: { eq: true } }) {
      nodes {
        salary
        seniority
        sections {
          list {
            en
            it
          }
          text {
            en
            it
          }
          title {
            en
            it
          }
          type
        }
        name {
          en
          it
        }
      }
    }
  }
`;
